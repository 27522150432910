import styled from "styled-components";

const ComboContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  align-items: center;
  width: 100%;
  max-width: 425px;
width: 90%;
`;

const LowerButton = styled.button`
  color: ${(props) => props.color || "#000"};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
  border: none;
  background: none;
`;

function SimpleButton({ bottomText }) {
  return (
    <ComboContainer>
      <LowerButton>{bottomText}</LowerButton>
    </ComboContainer>
  );
}

export default SimpleButton;
