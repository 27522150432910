import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGateCodes } from "../redux/manageSlice";
import { selectedTenantId } from "../redux/authSlice"; // Assuming you have a selector for the tenantId
import Code from "./Code";
import styled from "styled-components";
import { ReactComponent as Door } from "../assets/door_open.svg";
import { ReactComponent as Garage } from "../assets/garage.svg";
import { ReactComponent as Gate } from "../assets/fence.svg";
import Spinner from "./Spinner";

const CodeWrapper = styled.div`
  margin: 10px;
  background-color: #d4d5d5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  max-width: 425px;
  width: 90%;
`;

const Divider = styled.div`
  border-top: 3px dashed #bbb;
  content: " ";
  width: 100%;
  margin-top: 15px;
`;

const FullDivider = styled.div`
  border-top: 5px solid #fff;
  content: " ";
  width: 100%;
`;

const UnitHelp = styled.p`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 250% */
  letter-spacing: -0.24px;
  text-decoration-line: underline;
`;

const NoCodesMessage = styled.p`
  color: #555;
  font-size: 16px;
  text-align: center;
  margin: 30px 0;
`;

function Codes({ facilityId, onOpenModal }) {
  const dispatch = useDispatch();
  const { gateCodes, loading, error } = useSelector((state) => state.manage);
  const tenantId = useSelector(selectedTenantId);

  useEffect(() => {
    if (facilityId && tenantId) {
      dispatch(fetchGateCodes({ facilityId, tenantId }));
    }
  }, [dispatch, facilityId, tenantId]);


  return (
    <>
      {loading && <Spinner />}
      {error && <p>Error: {error}</p>}
      {!loading && !error && gateCodes && gateCodes.length === 0 && (<>
        <NoCodesMessage>No gate codes available at this time.</NoCodesMessage>
      </>
      )}
      {gateCodes &&
        gateCodes.length > 0 &&
        gateCodes.map((rental, index) => (
          <CodeWrapper>
            <div
              key={index}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Code
                icon={<Gate />}
                codeTitle="Gate Code"
                accessCode={rental.gate_codes.code || 29234243}
              />
              <Divider />
              {rental.gate_codes.door_code && (
                <>
                  <Code
                    icon={<Door />}
                    codeTitle="Door Code"
                    accessCode={rental.gate_codes.door_code}
                  />
                  <Divider />
                </>
              )}
              <Code
                icon={<Garage />}
                codeTitle="Storage Space #"
                accessCode={rental.rental_info.unit_name}
                onOpenModal={onOpenModal}
                unitHelp="Storage Space Map View"
              />
            </div>
          </CodeWrapper>
        ))}
    </>
  );
}

export default Codes;
