import BottomButton from "../components/BottomButton";
import HeaderLogin from "../components/HeaderLogin";
import AccountOption from "../components/AccountOptions";
import { useSelector } from "react-redux";
import { selectFacilityById, fetchFacility } from "../redux/facilitiesSlice";
import { selectedStore } from "../redux/authSlice";
import HelpIcons from "../components/HelperIcons";
import NickHeaderCenter from "../components/NickHeaderCenter";


function Account() {
  const storeId = useSelector(selectedStore);

  const facility = useSelector((state) => selectFacilityById(state, storeId));
  const { isAuthenticated, email, loading, loaded, s } = useSelector((state) => state.auth);
  const {first} = useSelector((state) => state.auth);
  return (
    <>
      <NickHeaderCenter brand_id={facility?.brand_id || null} home={false} auth={isAuthenticated} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "100px"
        }}
      >
        <AccountOption name={first} />
      </div>
      <HelpIcons />
    </>
  );
}

export default Account;
