import styled from "styled-components";

const ComboContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  align-items: center;
  width: 100%;
  max-width: 425px;
`;

const UpperButton = styled.button`
  border-radius: ${(props) => props.radius || "10px"};
  background: ${(props) => props.bg || "#e22c2a"};
  width: 100%;
  max-width: 400px;
  height: ${(props) => props.height || "59px"};
  color: ${(props) => props.color || "#fff"};
  font-size: 16px;
  font-weight: 700;
  border: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const LowerButton = styled.button`
  color: ${(props) => props.color || "#000"};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
  border: none;
  background: none;
`;

function ComboButton({
  idUpper,
  idLower,
  testIdUpper,
  testIdLower,
  width,
  radius,
  topText,
  bottomText,
  onUpperButtonClick,
  onBottomButtonClick,
  disabled,
}) {
  return (
    <ComboContainer>
      <UpperButton
        id={idUpper}
        data-testid={testIdUpper || idUpper}
        width={width}
        radius={radius}
        onClick={onUpperButtonClick}
        disabled={disabled}
      >
        {topText}
      </UpperButton>
      <LowerButton
        id={idLower}
        data-testid={testIdLower || idLower}
        onClick={onBottomButtonClick}
      >
        {bottomText}
      </LowerButton>
    </ComboContainer>
  );
}

export default ComboButton;
