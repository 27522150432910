import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ComboButton from "../components/ComboButton";
import NickHeaderCenter from "../components/NickHeaderCenter";
import Location from "../components/Location";
import PgText from "../components/PageText";
import Reservation from "../components/Reservation";
import BottomButton from "../components/BottomButton";
import { selectFacilityById, fetchFacility } from "../redux/facilitiesSlice";
import { selectedStore, selectedTenantId } from "../redux/authSlice";
import { fetchReservations } from "../redux/reservationSlice";
import { fetchRentalsForTenant } from "../redux/rentalsSlice";
import Spinner from "../components/Spinner";
import HelpIcons from "../components/HelperIcons";
import {
  createRentalStatus,
  resetCreateRentalStatus,
  setRentalsReady,
} from "../redux/rentalsSlice";
import {
  openModal,
  closeModal,
  nextModalStep,
  prevModalStep,
} from "../redux/modalSlice";
import { all } from "axios";

function MyReservation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Get location state
  const storeId = useSelector(selectedStore);
  const tenantId = useSelector(selectedTenantId);
  const facility = useSelector((state) => selectFacilityById(state, storeId));
  const { reservations, resLoading, resError } = useSelector(
    (state) => state.reservations
  );
  const { isRentModalOpen, currentModal, currentModalStep } = useSelector(
    (state) => state.modal
  );
  const { rentals: rawRentals } = useSelector((state) => state.rentals);
  const rentalStatus = useSelector(createRentalStatus); // Track rental creation status
  const selectedRentalId = useSelector(
    (state) => state.rentals.selectedRentalId
  );
  const [complete, setComplete] = useState(null);
  const [signedParam, setSignedParam] = useState(null); // Store the signedParam

  const rentalsReady = useSelector((state) => state.rentals.rentalsReady);
  const { isAuthenticated } = useSelector((state) => state.auth);

  // Ensure rentals is not null
  const rentals = rawRentals || [];

  useEffect(() => {
    // Extract the signedParam from URL on initial load
    const searchParams = new URLSearchParams(location.search);
    const param = searchParams.get("signed");
    if (param) {
      setSignedParam(param);
      setComplete(true); // Store signedParam in state
    }
  }, [location.search]);

  useEffect(() => {
    // Handle rentalsReady and signedParam logic
    if (signedParam && rentalsReady) {
      const matchedRental = allRentals.find((rental) => {
        if (rental?.phone && rental?.rentalInfo?.id?.toString() === signedParam) {
          return rental;
        }
      });

      if (matchedRental) {
        dispatch(
          openModal({
            type: 5, // Modal step 5
            unit: matchedRental.unitDetails,
            facilityId: storeId,
            reservation: matchedRental,
          })
        );

        // Reset state and URL parameters
        setSignedParam(null);
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("signed");
        navigate(`${location.pathname}?${searchParams.toString()}`, {
          replace: true,
        });
      }
    }
  }, [signedParam, rentalsReady, dispatch, storeId, navigate]);

  useEffect(() => {
    if (storeId && tenantId) {
      dispatch(fetchReservations({ facilityId: storeId, tenantId }));
      dispatch(fetchRentalsForTenant({ facilityId: storeId, tenantId }));
    }
  }, [dispatch, storeId, tenantId]);

  useEffect(() => {
    if (storeId && !facility) {
      dispatch(fetchFacility(storeId));
    }
  }, [dispatch, storeId, facility]);

  useEffect(() => {
    if (rentalStatus === "success") {
      dispatch(fetchReservations({ facilityId: storeId, tenantId }));
      dispatch(fetchRentalsForTenant({ facilityId: storeId, tenantId }));
      dispatch(resetCreateRentalStatus()); // Reset the status to avoid re-triggering
    }
  }, [rentalStatus, storeId, tenantId, dispatch]);

  const mergeRentalsIntoReservations = (reservations, rentals) => {
    const matchedRentals = [];
    const unmatchedRentals = [];

    const updatedReservations = reservations.map((reservation) => {
      if (reservation.status === "Hard" || reservation.status === "Rented") {
        const matchingRental = rentals.find(
          (rental) => rental.unit_id === reservation.unit_id
        );
        if (matchingRental) {
          matchedRentals.push(matchingRental.id);
          return {
            ...reservation,
            rentalInfo: matchingRental,
          };
        }
      }
      return reservation;
    });

    rentals.forEach((rental) => {
      if (!matchedRentals.includes(rental.id)) {
        let rrr = { ...rental };
        rrr.rentalInfo = { ...rental };
        unmatchedRentals.push(rrr);
      }
    });
    dispatch(setRentalsReady());
    return { updatedReservations, unmatchedRentals };
  };

  const { updatedReservations, unmatchedRentals } =
    mergeRentalsIntoReservations(reservations || [], rentals);
  const allRentals = [
    ...updatedReservations.filter(
      (reservation) =>
        reservation.status === "Hard" || reservation.status === "Rented"
    ),
    ...unmatchedRentals,
  ];

  const handleNextStep = (val) => {
    dispatch(nextModalStep(val));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <NickHeaderCenter
        brand_id={facility?.brand_id || null}
        home={false}
        auth={isAuthenticated}
        one={true}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingBottom: "100px",
        }}
      >
        <PgText title="Your Reservations" />
        {!resLoading && (
          <>
            {reservations?.filter(
              (reservation) =>
                reservation.status !== "Rented" && reservation.status !== "Hard"
            ).length > 0 ? (
              reservations
                .filter(
                  (reservation) =>
                    reservation.status !== "Rented" &&
                    reservation.status !== "Hard"
                )
                .map((reservation, ind) => (
                  <Reservation
                    key={reservation.id}
                    reservation={reservation}
                    facility={facility}
                    step={currentModalStep}
                    onClose={handleCloseModal}
                    onNext={handleNextStep}
                  />
                ))
            ) : (
              <div>No reservations found.</div>
            )}

            <PgText title="Your Rentals" />
            {allRentals.length > 0 ? (
              allRentals
                .filter(
                  (rental) => rental.status === "Rented" && rental.rentalInfo // Filter rentals with status "Rented" and a valid rentalInfo
                )
                .map((rental, ind) => (
                  <Reservation
                    key={rental.id}
                    reservation={rental}
                    facility={facility}
                    step={currentModalStep}
                    onClose={handleCloseModal}
                    onNext={handleNextStep}
                    leaseSignUrl={
                      rental.rentalInfo?.leaseSignUrl || rental.leaseSignUrl
                    }
                    rentalStatus={
                      rental.rentalInfo?.leaseStatus?.status ||
                      rental.leaseStatus?.status ||
                      null
                    }
                    complete={complete}
                  />
                ))
            ) : (
              <div>No rentals found.</div>
            )}
          </>
        )}
        {resLoading && <Spinner />}
        {facility && (
          <Location
            bg={facility?.beautyShot}
            title={facility?.name}
            address={facility?.address}
            city={facility?.city}
            state={facility?.state}
            zip={facility?.zip_code}
            officeHours={facility?.officeHours}
            accessHours={facility?.accessHours}
          />
        )}
      </div>
      <HelpIcons />
    </>
  );
}

export default MyReservation;
