// store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from '../redux/authSlice';
import facilitiesReducer from '../redux/facilitiesSlice';
import magicReducer from '../redux/magicSlice';
import manageReducer from '../redux/manageSlice';
import reservationReducer from './reservationSlice';
import rentalReducer from './rentalsSlice';
import unitsReducer from"./unitsSlice";
import paymentsReducer from './paymentsSlice';
import widgetReducer from '../redux/widgetSlice'; // import the widget slice
import profileSlice from './profileSlice';
import modalSlice from './modalSlice';


const persistConfig = {
  key: 'root',
  storage,
};


const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    facilities: facilitiesReducer,
    magic: magicReducer,
    manage: manageReducer,
    reservations: reservationReducer,
    rentals: rentalReducer,
    units: unitsReducer,
    payments: paymentsReducer,
    widget: widgetReducer,
    profile: profileSlice,
    modal: modalSlice

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
