import React, { useState } from "react";
import styled from "styled-components";
import Transactions from "./TransHistory";
import { ReactComponent as Visa } from "../assets/visa.svg";

const PHWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  width: 100%;
  max-width: 425px;
  width: 90%;
`;

const PaymentHistoryText = styled.p`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`;

const NoPaymentHistoryText = styled.p`
color: #555;
font-size: 16px;
text-align: center;
margin: 30px 0;
`;
const PHLink = styled.button`
  color: #e22c2a;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  background: #d4d5d5;
  border: none;
  border-radius: 10px;
  padding: 5px 20px;
`;

const TransactionsContainer = styled.div`
  width: 100%;
  max-width: 425px;
  width: 90%;
`;

function getCardType(number) {
  const cardPatterns = {
    1: /^4[0-9]{12}(?:[0-9]{3})?$/, // Visa
    2: /^(?:5[1-5][0-9]{14}|2[2-7][0-9]{14})$/, // MasterCard
    3: /^3[47][0-9]{13}$/, // American Express
    4: /^6(?:011|5[0-9]{2})[0-9]{12}$/, // Discover
    5: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/, // Diners Club
    6: /^(?:2131|1800|35\d{3})\d{11}$/, // JCB
  };

  for (const [key, pattern] of Object.entries(cardPatterns)) {
    if (pattern.test(number)) {
      return key; // Return the corresponding number
    }
  }

  return null; // If no match, return null
}


function PaymentHistory({ transactions }) {
  const [showAll, setShowAll] = useState(false);

  // Flatten the transactions array to get a single list of transactions
  const allTransactions = transactions
    .map((unit) =>
      unit.transactions.map((transaction) => ({
        ...transaction,
        unit_id: unit.unit_id, // Include the unit_id for reference
      }))
    )
    .flat();
    const filteredTransactions = allTransactions.filter((transaction) =>
      transaction.name.includes("Website Rental Payment -")
    );

  // Sort transactions by date, most recent first
  const sortedTransactions = filteredTransactions.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  // Limit to the first 3 transactions if showAll is false
  
  const transactionsToDisplay = showAll ? sortedTransactions : sortedTransactions.slice(0, 3);

  return (
    <>
      <PHWrap>
        <PaymentHistoryText>Payment History</PaymentHistoryText>
        {sortedTransactions.length > 3 && (
          <PHLink onClick={() => setShowAll((prev) => !prev)}>
            {showAll ? "See Less >" : "See All >"}
          </PHLink>
        )}
      </PHWrap>

      <TransactionsContainer>
        {sortedTransactions.length === 0 ? (
          <NoPaymentHistoryText>No payment history available at this time.</NoPaymentHistoryText>
        ) : (
          transactionsToDisplay.map((transaction, index) => (
            <Transactions
              key={index}
              icon={<Visa />} // Hardcoded Visa icon, adjust as needed
              lastfour={transaction.name.match(/-\s\*(\d+)/)?.[0]} // Using unit_id as a placeholder for card last four digits
              amount={transaction.amount.toFixed(2)}
              date={new Date(transaction.date).toLocaleDateString()}
              description={transaction.name} // Description of the transaction
              transaction={transaction}
            />
          ))
        )}
      </TransactionsContainer>
    </>
  );
}

export default PaymentHistory;
