// api/axios.js
import axios from 'axios';
import { store } from '../redux/store';
import { logoutUser } from '../redux/authSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Create an Axios instance
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a response interceptor
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 401 || status === 403) {
      store.dispatch(logoutUser());
      
      // Show a toast notification to inform the user
      toast.warn("Session expired. Please log in again.", {
        position: "top-center",
        autoClose: 5000,
      });

      setTimeout(() => {
        window.location.href = '/'; // Redirect to login page after 5 seconds
      }, 5000); // 5000 milliseconds = 5 seconds
    }
    return Promise.reject(error);
  }
);

export default instance;
