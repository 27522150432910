// reservationsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../api/axios";
import { loginWithQuery } from "./authSlice"; // Import login action
import { logout } from "./authSlice"; // Import the logout action

// Async thunk for fetching reservationsexportconst
export const fetchReservations = createAsyncThunk(
  "reservations/fetchReservations",
  async ({ facilityId, tenantId }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/facilities/${facilityId}/reservations`,
        {
          headers: {
            "encrypted-user-id": tenantId,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for creating a reservationexportconst
export const createReservation = createAsyncThunk(
  "reservations/createReservation",
  async ({ facilityId, reservationData, tenantId }, { dispatch, rejectWithValue }) => {
    try {
      let response;
      // If tenantId is not provided, create a new tenant and reservation
      if (!tenantId) {
        response = await instance.post(
          `/facilities/${facilityId}/tenant`,
          {
            first_name: reservationData.first_name,
            last_name: reservationData.last_name,
            email: reservationData.email,
            phone_number: reservationData.phone_number,
            sms_status: reservationData.sms_status,
            access_code: reservationData.access_code,
            reservation_type: reservationData.reservation_type,
            move_in_date: reservationData.move_in_date,
            unit_id: reservationData.unit_id,
            payment_amount: reservationData.payment_amount,
            discount_id: reservationData.discount_id
          }
        );
        
        // If encryptedUserId is returned, log in with it as `i`
        if (response.data.encryptedUserId) {
          dispatch(loginWithQuery(facilityId, response.data.encryptedUserId));
        }
      } else {
        // If tenantId is provided, directly create the reservation
        let url = reservationData.testCode?`/facilities/${facilityId}/reservations?simulate=${reservationData.testCode}`:`/facilities/${facilityId}/reservations`;
        response = await instance.post(
          url,
          {
            access_code: reservationData.access_code,
            reservation_type: reservationData.reservation_type,
            move_in_date: reservationData.move_in_date,
            unit_id: reservationData.unit_id,
            payment_amount: reservationData.payment_amount,
            discount_id: reservationData.discount_id
          },
          {
            headers: {
              "encrypted-user-id": tenantId,
            },
          }
        );
      }

      return response.data;
      
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);
export const cancelRental = createAsyncThunk(
  "reservations/cancelRental",
  async ({ facilityId, tenantId, reservationId }, { rejectWithValue }) => {
    try {
      const response = await instance.delete(
        `/facilities/${facilityId}/reservations/${reservationId}`,
        {
          headers: {
            "encrypted-user-id": tenantId,
          },
        }
      );
      return { reservationId, ...response.data }; // Include reservationId in response for local state updates
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to cancel reservation");
    }
  }
);

// Async thunk for editing a reservationexportconst
export const editReservation = createAsyncThunk(
  "reservations/editReservation",
  async (
    { facilityId, tenantId, reservationId, reservationData },
    { rejectWithValue }
  ) => {
    try {
      const response = await instance.put(
        `/facilities/${facilityId}/reservations/${reservationId}`,
        reservationData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for deleting a reservationexportconst
export const deleteReservation = createAsyncThunk(
  "reservations/deleteReservation",
  async ({ facilityId, tenantId, reservationId }, { rejectWithValue }) => {
    try {
      const response = await instance.delete(
        `/facilities/${facilityId}/reservations/${reservationId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const reservationsSlice = createSlice({
  name: "reservations",
  initialState: {
    reservations: [],
    resLoading: false,
    resError: null,
    successMessage: null, // Add successMessage to state
  },
  reducers: {
    clearSuccessMessage: (state) => {
      state.successMessage = null; // Reducer to clear the success message
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReservations.pending, (state) => {
        state.resLoading = true;
        state.resError = null;
      })
      .addCase(fetchReservations.fulfilled, (state, action) => {
        state.resLoading = false;
        state.reservations = action.payload;
      })
      .addCase(fetchReservations.rejected, (state, action) => {
        state.resLoading = false;
        state.resError = action.payload;
      })
      .addCase(createReservation.fulfilled, (state, action) => {
        state.reservations.push(action.payload);
        state.successMessage = "Success!";
      })
      .addCase(createReservation.rejected, (state, action) => {
        state.resLoading = false;
        state.resError = action.payload; // Capture the structured error
      })
      .addCase(editReservation.fulfilled, (state, action) => {
        const index = state.reservations.findIndex(
          (reservation) => reservation.id === action.payload.id
        );
        if (index !== -1) {
          state.reservations[index] = action.payload;
        }
      })
      .addCase(deleteReservation.fulfilled, (state, action) => {
        state.reservations = state.reservations.filter(
          (reservation) => reservation.id !== action.payload.id
        );
      })
      .addCase(cancelRental.pending, (state) => {
        state.resLoading = true;
        state.resError = null;
      })
      .addCase(cancelRental.fulfilled, (state, action) => {
        state.resLoading = false;
        state.reservations = state.reservations.filter(
          (reservation) => reservation.id !== action.payload.reservationId
        );
        state.successMessage = "Reservation canceled successfully";
      })
      .addCase(cancelRental.rejected, (state, action) => {
        state.resLoading = false;
        state.resError = action.payload;
      })
      .addCase(logout, () => ({
        reservations: [],
        resLoading: false,
        resError: null,
        successMessage: null,
      }));
  },
});

export const { clearSuccessMessage } = reservationsSlice.actions;
export default reservationsSlice.reducer;
