import styled from "styled-components";


const TitleWrap = styled.div`
display: flex ;
flex-direction: column;
margin: 10px ;
justify-content: center;
align-items:center;
`

const PageTitleText = styled.p`
color: #000;
font-size: 20px;
font-style: normal;
font-weight: 700;
margin-bottom: 0 ;
text-align: center;
`

const PageBodyText = styled.p `
color: #000;
font-size: 14px;
font-style: normal;
font-weight: 400;
margin-top: 3px ;
tex-align: center;
`


function PgText (obj) {

    return (
        <>
            <TitleWrap>
                <PageTitleText>{obj.title}</PageTitleText>
                <PageBodyText>{obj.text}</PageBodyText>
             </TitleWrap>
        </>
    );
}

export default PgText ;