import React, { useState } from "react";
import styled from "styled-components";

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
`;

const FilterButton = styled.button`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-height: 50px; // Set a minimum height to ensure consistency
  background-color: ${(props) => (props.active ? "#fdeceb" : "white")};
  color: #333;
  border: none;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  text-align: center;

  &:hover {
    background-color: ${(props) => (props.active ? "#fdeceb" : "#f5f5f5")};
  }

  &:not(:last-child) {
    border-right: 1px solid #ddd;
  }
`;

function UnitSizeFilter({ onFilterChange }) {
  const [selectedSize, setSelectedSize] = useState("View All");

  const handleFilterClick = (size) => {
    setSelectedSize(size);
    onFilterChange(size);
  };

  return (
    <FilterContainer>
      {["View All", "Small", "Medium", "Large", "Vehicle"].map((size) => (
        <FilterButton
          key={size}
          active={selectedSize === size}
          onClick={() => handleFilterClick(size)}
        >
          {size}
        </FilterButton>
      ))}
    </FilterContainer>
  );
}

export default UnitSizeFilter;
