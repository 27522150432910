import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 5px 10px;
`;

const ToggleSwitch = styled.div`
  width: 40px;
  height: 20px;
  border-radius: 15px;
  background-color: ${(props) => (props.isActive ? "#e22c2a" : "#ccc")};
  position: relative;
  transition: background-color 0.3s;
  cursor: pointer;

  &::after {
    content: "${(props) => (props.isActive ? "✓" : "")}";
    color: white;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    left: 6px;
    top: 2px;
  }
`;

const ToggleCircle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 1px;
  left: ${(props) => (props.isActive ? "20px" : "1px")};
  transition: left 0.3s;
`;

const Label = styled.span`
  font-size: 11px;
  font-weight: bold;
`;

const TooltipIcon = styled.span`
  font-size: 14px;
  color: #333;
  cursor: pointer;
  position: relative;
  margin-left: 5px;
`;

const TooltipText = styled.div`
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  width: 300px;
  background-color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: fixed;
  z-index: 1002;
  font-size: 10px;
  line-height: 1.2;
  transition: opacity 0.3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")}; /* Allow interaction only when visible */
`;

const SmsToggle = ({ checked, onChange, tooltipVisible, setTooltipVisible }) => {
  const [isActive, setIsActive] = useState(checked || true);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  let tooltipTimeout; // Reference for the timeout

  const handleToggle = () => {
    onChange(!isActive);
    setIsActive((prevState) => !prevState);
  };

  const showTooltip = (event) => {
    clearTimeout(tooltipTimeout); // Clear any existing timeout
    const tooltipWidth = 300; // Width of the tooltip
    const tooltipHeight = 100; // Estimated height of the tooltip
    const viewportWidth = window.innerWidth;
    const { top, left, width } = event.target.getBoundingClientRect();

    let calculatedLeft = left + width / 2 - tooltipWidth / 2;
    let calculatedTop = top - tooltipHeight - 10; // Above the icon

    // Adjust if out of bounds
    if (calculatedLeft < 0) calculatedLeft = 10;
    if (calculatedLeft + tooltipWidth > viewportWidth)
      calculatedLeft = viewportWidth - tooltipWidth - 10;
    if (calculatedTop < 0) calculatedTop = top + 30; // Position below if no space above

    setTooltipPosition({ top: calculatedTop, left: calculatedLeft });
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    tooltipTimeout = setTimeout(() => {
      setTooltipVisible(false);
    }, 200); // Adjust the delay as needed
  };

  return (
    <ToggleContainer>
      <ToggleSwitch isActive={isActive} onClick={handleToggle}>
        <ToggleCircle isActive={isActive} />
      </ToggleSwitch>
      <Label>Text me about my reservation</Label>
      <TooltipIcon
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
        <TooltipText
          visible={tooltipVisible}
          style={{
            top: `${tooltipPosition.top}px`,
            left: `${tooltipPosition.left}px`,
          }}
          onMouseEnter={() => clearTimeout(tooltipTimeout)} // Keep visible on tooltip hover
          onMouseLeave={hideTooltip} // Hide when mouse leaves the tooltip
        >
          By entering your number and checking this box you agree to receive
          marketing and promotional messages and/or non-marketing updates and
          information including text messages about your reservation from
          iStorage at the number provided. Consent is not a condition of
          purchase. Message and data rates may apply. Message frequency varies.
          Reply HELP for help or STOP to cancel. View our{" "}
          <a
            href="https://www.nsastorage.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            href="https://www.nsastorage.com/mobile-terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          .
        </TooltipText>
      </TooltipIcon>
    </ToggleContainer>
  );
};

export default SmsToggle;
