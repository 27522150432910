import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import BillPay from './Pages/BillPayPage';
import AccessCodes from './Pages/AccessCodesPage';
import Account from './Pages/Account';
import UserProfile from './Pages/UserProfile';
import PayMyBill from './Pages/PayMyBill';
import Rental from './Pages/Rental';
import NewRental from './Pages/NewRental';
import Reservations from './Pages/Reservations';
import MyReservation from './Pages/MyReservation';
import ManageUnits from './Pages/ManageUnits';
import ModalManager from './components/ModalManager';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/billpay" element={<BillPay />} />
        <Route path="/accesscodes" element={<AccessCodes />} />
        <Route path="/account" element={<Account />} />
        <Route path="/userprofile" element={<UserProfile />} />
        <Route path="/paymybill" element={<PayMyBill />} />
        <Route path="/rental" element={<Rental />} />
        <Route path="/newrental" element={<NewRental />} />
        <Route path="/myreservations" element={<MyReservation />} />
        <Route path="/manageunits" element={<ManageUnits />} />
      </Routes>
      <ModalManager />
    </Router>
  );
}

export default App;
