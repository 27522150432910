// Modal.js
import React from "react";
import PropTypes from "prop-types";
import "./Modal.css"; // Assume you have some CSS for the modal

function Modal({ children, onClose, fullScreen, id }) {
  return (
    <div className={`modal ${fullScreen ? "modal-fullscreen" : ""}`}>
      <div className="modal-content">
        <button
          className="modal-close"
          onClick={() => {
            onClose();
          }}
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
};

Modal.defaultProps = {
  fullScreen: false,
};

export default Modal;
