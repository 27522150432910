import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CalendarIcon from "../assets/CalendarIcon.png";
import PinpadIcon from "../assets/PinpadIcon.png";
import LocationPinIcon from "../assets/LocationPinIcon.png";
import ClockIcon from "../assets/ClockIcon.png";
import WarehouseIcon from "../assets/WarehouseIcon.png";
import { selectedStore } from '../redux/authSlice';
import { selectFacilityById } from "../redux/facilitiesSlice";
import { useSelector } from "react-redux";
import {
  faCheck,
  faClipboard,
  faCreditCard,
  faFileContract,
  faMapMarkerAlt as faMoveIn,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

// Styled Components
const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  max-width: 400px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;
const ItemContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const IconContainer = styled.div`
  margin-bottom: 10px;
`;

const IconImage = styled.img`
  width: 40px;
`;

const Label = styled.p`
  font-weight: bold;
  color: #333;
  font-size: 14px;
  margin: 0;
`;

const Value = styled.p`
  color: #666;
  font-size: 16px;
  margin: 0;
`;

const MapLink = styled.a`
  color: #e22c2a;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #c92121;
  }
`;
const Hours = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 5px;
`;

const HoursTitle = styled.p`
  font-size: 14px;
  margin: 1px;
`;
const HoursText = styled.span`
  font-size: 14px;
  margin: 1px;
`;
const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;

  &:not(:first-child)::before {
    content: "";
    position: absolute;
    top: 30%;
    left: -30%;
    transform: translateY(-30%);
    height: 2px;
    width: 100%;
    background-color: #e22c2a;
    z-index: 0;
  }
`;

const StepCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => (props.completed ? "#e22c2a" : "#f5f5f5")};
  color: ${(props) => (props.completed ? "#fff" : "#e22c2a")};
  border: 2px solid #e22c2a;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const StepLabel = styled.p`
  font-size: 10px;
  margin-top: 5px;
  text-align: center;
`;
const ModalNavigationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const NavButton = styled.button`
  background-color: #e22c2a;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #c92121;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const SignLeaseModal = ({ onRequestClose, unit, reservation }) => {
  const storeId = useSelector(selectedStore);
  const facility = useSelector((state) => selectFacilityById(state, storeId));
  const handleWrapperClick = (e) => {
    if (e.target === e.currentTarget) {
      onRequestClose();
    }
  };
  const dateStr = reservation.move_in_date;
  const date = new Date(dateStr);

  // Format the date as "Thu, Oct 31"
  const options = { weekday: "short", month: "short", day: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  const address = `${facility.address}, ${facility.city}, ${facility.state} ${facility.zip_code}`;
  const googleMapsLink = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`;
  return (
    <ModalWrapper onClick={handleWrapperClick}>
      <ModalContainer>
        <h1 style={{ textAlign: "center" }}>Your rental is almost complete!</h1>
        <StepsContainer>
          <Step>
            <StepCircle completed>
              <FontAwesomeIcon icon={faCreditCard} />
            </StepCircle>
            <StepLabel>Enter Payment</StepLabel>
          </Step>
          <Step>
            <StepCircle completed>
              <FontAwesomeIcon icon={faUser} />
            </StepCircle>
            <StepLabel>Alt Contact</StepLabel>
          </Step>
          <Step>
            <StepCircle completed>
              <FontAwesomeIcon icon={faClipboard} />
            </StepCircle>
            <StepLabel>More Info</StepLabel>
          </Step>
          <Step>
            <StepCircle>
              <FontAwesomeIcon icon={faFileContract} />
            </StepCircle>
            <StepLabel>Sign Lease</StepLabel>
          </Step>
        </StepsContainer>
        <>
          <p style={{ textAlign: "center", color: "#333" }}>
            Please sign your lease! If you have questions about your rental,
            please contact your facility via the information below. Thank you!
          </p>
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              style={{
                backgroundColor: "#e22c2a",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                padding: "10px 20px",
                margin: "25px",
                cursor: "pointer",
                fontSize: "18px",
              }}
              onClick={() => {
                window.location.href = reservation.rentalInfo.leaseSignUrl
                ;
              }}
            >
              Sign Lease
            </button>
          </div>
        </>

        <ItemContainer>
          <IconContainer>
            <IconImage src={CalendarIcon} alt="Unit Icon" />
          </IconContainer>
          <Label>Move In Date</Label>
          <Value>{formattedDate}</Value>
        </ItemContainer>

        <ItemContainer>
          <IconContainer>
            <IconImage src={WarehouseIcon} alt="Unit Icon" />
          </IconContainer>
          <Label>UNIT NUMBER</Label>
          <Value>{unit.name}</Value>
        </ItemContainer>

        <ItemContainer>
          <IconContainer>
            <IconImage src={PinpadIcon} alt="Gate Code Icon" />
          </IconContainer>
          <Label>GATE CODE</Label>
          <Value>{reservation.phone.slice(-7)}</Value>
        </ItemContainer>

        <ItemContainer>
          <IconContainer>
            <IconImage src={ClockIcon} alt="Access Hours Icon" />
          </IconContainer>
          <Label>ACCESS HOURS</Label>
          <Value>
            {/* Mon - Sun: 6:00 AM - 10:00 PM */}
            <Hours>
              {facility.accessHours.map((hour, index) => (
                <HoursText key={index}>{hour}</HoursText>
              ))}
            </Hours>
          </Value>
        </ItemContainer>

        <ItemContainer>
          <IconContainer>
            <IconImage src={LocationPinIcon} alt="Directions Icon" />
          </IconContainer>
          <Label>DIRECTIONS</Label>
          <MapLink
            href={googleMapsLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Map it
          </MapLink>
        </ItemContainer>
        <ModalNavigationContainer>
          <NavButton onClick={onRequestClose}>Close</NavButton>
        </ModalNavigationContainer>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default SignLeaseModal;
