import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PgText from "../components/PageText";
import Location from "../components/Location";
import Codes from "../components/Codes";
import BottomButton from "../components/BottomButton";
import MapModal from "../components/MapModal";
import { selectFacilityById, fetchFacility } from "../redux/facilitiesSlice";
import { selectedStore } from "../redux/authSlice";
import Spinner from "../components/Spinner";
import HelpIcons from "../components/HelperIcons";
import NickHeaderCenter from "../components/NickHeaderCenter"
import { selectedTenantId } from "../redux/authSlice"; // Assuming you have a selector for the tenantId


function AccessCodes() {
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeId = useSelector(selectedStore);
  const facility = useSelector((state) => selectFacilityById(state, storeId));
  const { isAuthenticated, email, first, loading, loaded, s } = useSelector((state) => state.auth);
  const tenantId = useSelector(selectedTenantId);
  useEffect(() => {
    if (storeId && !facility) {
      dispatch(fetchFacility(storeId, tenantId));
    }
  }, [dispatch, storeId, facility]);

  if (!facility) {
    return<Spinner />; // You can show a loading spinner here
  }

  return (
    <>
      <NickHeaderCenter brand_id={facility?.brand_id || null} home={false} auth={isAuthenticated} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingBottom: "100px"
        }}
      >
       
        <div style={{ display:"flex", flexDirection:'column', justifyContent:'center', alignItems:"center", width:'100%' }}>
          <PgText
            title={`Find my gate, door code & storage space number`}
            text="Gate code, Door code, & storage space number"
          />
          <Codes facilityId={storeId} onOpenModal={() => setModalOpen(true)} />
          <MapModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
        </div>
        {facility &&  <Location
          bg={facility?.beautyShot}
          title={facility?.name}
          address={facility?.address}
          city={facility?.city}
          state={facility?.state}
          zip={facility?.zip_code}
          officeHours={facility?.officeHours}
          accessHours={facility?.accessHours}
        />}
      </div>
      <HelpIcons />
    </>
  );
}

export default AccessCodes;
