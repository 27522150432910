import styled from "styled-components";
import {ReactComponent as NSALogo2} from '../assets/nsaLogo2.svg';
import { useNavigate } from 'react-router-dom';

const HeaderCenterWrap = styled.div`
display: flex ;
justify-content: center ;
margin-top: 0px ;
margin-bottom:5px
`

function HeaderCenter () {
    let navigate = useNavigate();
    return (
        <HeaderCenterWrap>
            <NSALogo2 onClick={()=>{navigate('/')}} />
        </HeaderCenterWrap>
    );
}

export default HeaderCenter ;