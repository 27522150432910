import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReservationModal from "./ReservationModal";
import RentalModal from "./RentalModal";
import AlternateContactModal from "./AlternateContactModal";
import ExtendedContactModal from "./ExtendedContactModal";
import UnitInfoModal from "./UnitInfoModal";
import {
  closeModal,
  nextModalStep,
  prevModalStep,
  updateActiveRental,
} from "../redux/modalSlice";
import SignLeaseModal from "./SignLeaseModal";

function ModalManager() {
  const dispatch = useDispatch();
  const rentals = useSelector((state) => state.rentals.rentals);
  const reservations = useSelector((state) => state.reservations.reservations);
  const selectedRentalId = useSelector(
    (state) => state.rentals.selectedRentalId
  );
  const tenant = useSelector((state) => state.auth.profile);
  const facilityId = useSelector((state) => state.auth.s);
  const {
    isRentModalOpen,
    currentModalStep,
    selectedUnit,
    selectedReservation,
  } = useSelector((state) => state.modal);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleNext = (val) => {
    dispatch(nextModalStep(val));
  };

  const handlePrev = () => {
    dispatch(prevModalStep());
  };

  // Logic to merge rentals into reservations and include unitDetails
  const mergeRentalsIntoReservations = (reservations, rentals) => {
    const matchedRentals = [];
    const unmatchedRentals = [];

    const updatedReservations = reservations.map((reservation) => {
      if (reservation.status === "Hard" || reservation.status === "Rented") {
        const matchingRental = rentals.find(
          (rental) => rental.unit_id === reservation.unit_id
        );
        if (matchingRental) {
          matchedRentals.push(matchingRental.id);
          return {
            ...reservation,
            rentalInfo: matchingRental,
            unitDetails: matchingRental.unit, // Attach unitDetails from the rental
          };
        }
      }
      return reservation;
    });

    rentals.forEach((rental) => {
      if (!matchedRentals.includes(rental.id)) {
        let rrr = { ...rental };
        rrr.rentalInfo = { ...rental };
        unmatchedRentals.push(rrr);
      }
    });

    return { updatedReservations, unmatchedRentals };
  };

  useEffect(() => {
    if (reservations && rentals) {
      const { updatedReservations } = mergeRentalsIntoReservations(
        reservations,
        rentals
      );

      if (!selectedReservation && selectedUnit) {
        // Match selectedReservation by unit ID if no selectedReservation exists
        const matchedReservation = updatedReservations.find(
          (reservation) =>
            reservation.unit_id === selectedUnit.first_available_unit_id
        );

        if (
          matchedReservation &&
          (!selectedReservation ||
            selectedReservation.unit_id !== matchedReservation.unit_id)
        ) {


          dispatch(
            updateActiveRental({
              unit: selectedUnit,
              facilityId,
              reservation: {
                ...matchedReservation,
                unitDetails: selectedUnit, // Add unitDetails to the reservation
              },
              step: 1,
            })
          );
        }
      } else if (selectedReservation) {
        // Update selectedReservation if it exists
        const matchedReservation = updatedReservations.find(
          (reservation) => reservation.unit_id === selectedReservation.unit_id
        );

        if (
          matchedReservation &&
          (!selectedReservation.rentalInfo ||
            selectedReservation.rentalInfo.id !==
              matchedReservation.rentalInfo.id)
        ) {
          dispatch(
            updateActiveRental({
              unit: selectedUnit,
              facilityId,
              reservation: {
                ...matchedReservation,
                unitDetails: selectedUnit, // Add unitDetails to the reservation
              },
              step: currentModalStep,
            })
          );
        }
      }
    }
  }, [
    reservations,
    rentals,
    selectedReservation?.unit_id,
    selectedUnit?.unit_id,
    dispatch,
    facilityId,
    currentModalStep,
  ]);

  if (!isRentModalOpen) return null;

  switch (currentModalStep) {
    case 0:
      return (
        <ReservationModal
          isOpen={isRentModalOpen}
          onRequestClose={handleClose}
          facilityId={facilityId}
          unit={selectedUnit}
          onNext={handleNext}
        />
      );
    case 1:
      return (
        <RentalModal
          isOpen={isRentModalOpen}
          onRequestClose={handleClose}
          onNext={handleNext}
          facilityId={facilityId}
          unit={selectedUnit}
          reservation={selectedReservation}
        />
      );
    case 2:
      return (
        <AlternateContactModal
          isOpen={isRentModalOpen}
          onRequestClose={handleClose}
          onNext={handleNext}
          reservation={selectedReservation}
          facilityId={facilityId}
        />
      );
    case 3:
      return (
        <ExtendedContactModal
          isOpen={isRentModalOpen}
          onRequestClose={handleClose}
          onNext={handleNext}
          reservation={selectedReservation}
          facilityId={facilityId}
        />
      );
    case 4:
      return (
        <SignLeaseModal
          isOpen={isRentModalOpen}
          onRequestClose={handleClose}
          unit={selectedUnit}
          onNext={handleNext}
          reservation={selectedReservation}
          facilityId={facilityId}
        />
      );
    case 5:
      return (
        <UnitInfoModal
          isOpen={isRentModalOpen}
          onRequestClose={handleClose}
          unit={selectedUnit}
          reservation={selectedReservation}
          facilityId={facilityId}
        />
      );
    default:
      return null;
  }
}

export default ModalManager;
