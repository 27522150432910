import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Location from "../components/Location";
import PgText from "../components/PageText";
import Input from "../components/Input";
import styled from "styled-components";
import SimpleButton from "../components/SimpleButton";
import BottomButton from "../components/BottomButton";
import OptionText from "../components/OptionItem";
import HeaderCenter from "../components/HeaderCenter";
import { selectFacilityById, fetchFacility } from "../redux/facilitiesSlice";
import { selectedStore } from "../redux/authSlice";
import HelpIcons from "../components/HelperIcons";

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DividerText = styled.p`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

const DividerBefore = styled.div`
  content: "";
  height: 1px;
  background-color: silver;
  flex-grow: 1;
  margin: 5px;
`;

const DividerAfter = styled.div`
  content: "";
  height: 1px;
  background-color: silver;
  flex-grow: 1;
  margin: 5px;
`;

function Reservations() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeId = useSelector(selectedStore);
  const facility = useSelector((state) => selectFacilityById(state, storeId));

  useEffect(() => {
    if (storeId && !facility) {
      dispatch(fetchFacility(storeId));
    }
  }, [dispatch, storeId, facility]);

  if (!facility) {
    return <div>Loading...</div>; // You can show a loading spinner here
  }

  return (
    <>
      <div
        style={{
          margin: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HeaderCenter />
        {facility && <Location
          bg={facility?.beautyShot}
          title={facility?.name}
          address={facility?.address}
          city={facility?.city}
          state={facility?.state}
          zip={facility?.zip_code}
          officeHours={facility?.officeHours}
          accessHours={facility?.accessHours}
        />}
        <PgText
          title="Complete my rental"
          text="Already have a reservation or rental started? Search below to complete your lease and move in."
        />
        <Input radius="2px" labelText="Email" placeholder="Enter email" />
        <OptionText
          onClick={() => {
            navigate("/myreservations");
          }}
          title="Search for reservation"
        />
        <BottomButton title="Back to Home" land="/" />
        <SimpleButton
          onClick={() => {
            navigate("/");
          }}
          topText="Back to Home"
          bottomText="Rent a new storage space"
        />
      </div>
      <HelpIcons />
    </>
  );
}

export default Reservations;
