import styled from "styled-components";
import { ReactComponent as NSALogo2 } from '../assets/nsaLogo2.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../redux/authSlice';

const LoginButton = styled.button`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  background: none;
  border: none;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  margin-top: 30px;
`;

function HeaderLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/');
  };

  return (
    <HeaderWrapper>
      <NSALogo2 onClick={() => navigate('/')} />
      {/* {isAuthenticated ? (
        <LoginButton onClick={handleLogout}>Logout</LoginButton>
      ) : (
        <LoginButton onClick={() => navigate('/login')}>Login</LoginButton>
      )} */}
    </HeaderWrapper>
  );
}

export default HeaderLogin;
