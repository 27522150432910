import styled from "styled-components";
import Overlay from "./Overlay";

const LocationImage = styled.div`
  background-image: url("${({ bg }) => bg}");
  height: 300px;
  background-color: blue;
  width: 100vw;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 100px;
  // position: relative;
`;

function Location({ bg, title, address, city, state, zip, officeHours, accessHours }) {
  return (
    <>
      {/* <LocationImage bg={bg}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        > */}
          <Overlay
            title={title}
            address={address}
            city={city}
            state={state}
            zip={zip}
            officeHours={officeHours}
            accessHours={accessHours}
          />
        {/* </div> */}
      {/* </LocationImage> */}
    </>
  );
}

export default Location;
