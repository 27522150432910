import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
export const MapContext = React.createContext({
  map: null,
  units: null,
  levels: null
});

const UnitMap = ({ id, apiKey, defaultZoom, width, height, children, selectedUnit }) => {
  const elementRef = useRef(null);
  const [mapState, setMapState] = useState({
    map: null,
    units: null,
    levels: null,
  });
  const [isZoomed, setIsZoomed] = useState(false);
  const [locator, setLocator] = useState(null);
  const [locatorVisible, setLocatorVisible] = useState(true);

  useEffect(() => {
    let map = unitmap(elementRef.current, apiKey);

    map.load(id, async () => {
      setMapState({
        map: map,
        units: map.units(),
        levels: map.levels(),
      });

      // Set up locator
      const newLocator = map.locator();
      setLocator(newLocator);
      newLocator.translate([925, 1233]).show();

      // Set default zoom level if provided
      if (defaultZoom && map.zoomTo) {
        map.zoomTo(defaultZoom);
      }

      // Function to handle zoom and center map on specific unit
      function doZoom() {
        if (!isZoomed) {
          setIsZoomed(true);
          map.zoomTo(map.units().find("5984749").center(), 0.3);
        }
      }

      map.on('ready', doZoom);

      // Event to move the locator under the mouse click
      elementRef.current.addEventListener('click', (event) => {
        if (locator) {
          const localCoords = map.toLocal([event.clientX, event.clientY]);
          locator.translate(localCoords);
        }
      });
    });

    // Clean up when component unmounts
    return () => {
      if (map) {
        map.off('ready');
        map.off('zoom');
      }
    };
  }, [id, apiKey, defaultZoom, isZoomed]);

  const toggleLocatorVisibility = () => {
    if (locator) {
      locatorVisible ? locator.hide() : locator.show();
      setLocatorVisible(!locatorVisible);
    }
  };

  return (
    <div ref={elementRef} style={{ width, height }}>
      <MapContext.Provider value={mapState}>
        {children}
        <button onClick={toggleLocatorVisibility}>
          {locatorVisible ? 'Hide Locator' : 'Show Locator'}
        </button>
      </MapContext.Provider>
    </div>
  );
};

UnitMap.defaultProps = {
  width: '100%',
  height: '100%',
};

UnitMap.propTypes = {
  id: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultZoom: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UnitMap;
