import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../api/axios";
import { logout } from "./authSlice"; // Import the logout action

// Async thunk for adding an alternate contact


const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profile: null, // User profile details
    loading: false,
    error: null,
    successMessage: null, // For success notifications
  },
  reducers: {
    clearProfileMessages: (state) => {
      state.error = null;
      state.successMessage = null;
    },
    updateProfileDetails: (state, action) => {
      state.profile = { ...state.profile, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      
      .addCase(logout, () => ({
        profile: null,
        alternateContacts: [],
        loading: false,
        error: null,
        successMessage: null,
      }));
  },
});

export const { clearProfileMessages, updateProfileDetails } = profileSlice.actions;
export default profileSlice.reducer;
