import styled from "styled-components";

export const UnitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
`;
export const UnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
export const UnitWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
`;

export const UnitImage = styled.img`
  height: 70px;
`;

export const Note = styled.p`
  font-size: 10px;
  color: #666;
  margin-top: 10px;
  text-align: center;
`;

export const NoteI = styled.p`
  font-size: 10px;
  color: #e22c2a;
  margin-top: 10px;
  text-align: center;
  font-style: italic;
`;

export const NoteH = styled.p`
  font-size: 10px;
  color: #666;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const UnitInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const UnitTopInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
`;

export const UnitName = styled.p`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;

export const UnitSize = styled.p`
  color: #767374;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

export const UnitPrice = styled.p`
  color: #e22c2a;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;

export const UnitStrike = styled.p`
  color: #767374;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: line-through;
  margin: 0;
`;

export const UnitDivider = styled.div`
  content: "";
  height: 1px;
  background-color: #d3d3d3;
  flex-grow: 1;
  margin: 20px 5px;
  width: 75%;
  display: flex;
  align-self: center;
`;

export const MathDivider = styled.span`
  content: "";
  height: 1px;
  background-color: #d3d3d3;
  flex-grow: 1;
  margin: 20px 5px;
  display: flex;
  align-self: center;
`;

export const UnitsDivider = styled.div`
  content: "";
  height: 1px;
  background-color: gray;
  flex-grow: 1;
  margin: 20px 5px;
`;

export const UnitFeature = styled.div`
  color: #767374;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
`;

export const IconWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
`;

export const UnitFeatureTitle = styled.div`
  font-size: 10px;
  color: #767374;
  text-align: center;
  margin-top: 4px;
`;

export const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: #d3d3d3;
  align-self: center;
`;

export const DiscountBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fdecea; /* Light red background */
  color: #e22c2a; /* Red color for text */
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const BadgeIcon = styled.div`
  margin-right: 5px;
`;

export const CancelButton = styled.button`
  text-decoration: underline;
  font-size: 14px;
  background: none;
  border: none;
  margin-top: 20px;
  color: #5c5d5d;
`;

export const PriceBreakdownContainer = styled.div`
  border: 1px dashed #d3d3d3;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
`;

export const BreakdownRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  font-size: 14px;
  color: #333;
  align-items: center;
`;

export const BreakdownTitle = styled.p`
  font-weight: bold;
`;

export const BreakdownTotal = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #e22c2a;
`;

export const ActionButton = styled.button`
border-radius: ${(props) => props.radius || "10px"};
  background: ${(props) => props.bg || "#e22c2a"};
  width: 100%;
  max-width: 400px;
  height: ${(props) => props.height || "59px"};
  color: ${(props) => props.color || "#fff"};
  font-size: 16px;
  font-weight: 700;
  border: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const ToggleButton = styled.button`
  background-color: white;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  font-size: 14px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #f3f3f3;
  }

  svg {
    margin-left: 5px;
    transition: transform 0.3s ease;
    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
  }
`;

export const ModalNavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const NavButton = styled.button`
  background-color: #e22c2a;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #c92121;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
