import styled from "styled-components";
import {ReactComponent as User} from '../assets/user.svg';
import {ReactComponent as Arrow} from '../assets/arrow.svg' ;


const Wrapper = styled.div`
display: flex ;
flex-direction: row ;
align-items: center;
justify-content: space-between;
padding:15px;
margin:5px 10px;
background: #d4d5d5 ;
border-radius: 5px ;
`

const IconPusTitle = styled.div`
display: flex ;
flex-direction: row ;
width: 400px ;
gap: 10px ;
align-items: center ;
`

const ManageTitle = styled.p`
color: #000;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
`




const AccountSelector = ({ onClick, icon, mgTitle }) => {
    return (
        <>
            <Wrapper  onClick={onClick}>
                <IconPusTitle>
                    {icon}
                    <ManageTitle>{mgTitle}</ManageTitle>
                </IconPusTitle>
                     <Arrow/>
            </Wrapper>
        </>
    );

}

export default AccountSelector;