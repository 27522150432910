import styled from "styled-components";
import { ReactComponent as Globe } from "../assets/globe.svg";
import { ReactComponent as Clock } from "../assets/clock.svg";
import { ReactComponent as LocationSpot } from "../assets/location.svg";

const OverlayBg = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  width: 90%;
  background: #d4d5d5;
  z-index: 2;
  border-radius: 10px;
  margin: 30px auto;
  max-width: 450px;
  padding: 10px;
`;

const OvelayTitle = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  margin-top: 20px;
  align-items: flex-start;
  margin: 0px 10px;
`;
const OverlayAddress = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
`;

const HoursWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 10px;
`;
const Hours = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 5px;
`;

const HoursTitle = styled.p`
  font-size: 14px;
  margin: 1px;
`;
const HoursText = styled.span`
  font-size: 10px;
  margin: 1px;
`;

const StoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: flex-start;
  padding-left: 10px;
`;

const StoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const DividerLine = styled.div`
  content: " ";
  width: 90%;
  border-bottom: 1.5px dashed black;
  margin: 5px auto;
  padding-top: 10px;
`;

function Overlay({
  title,
  address,
  city,
  state,
  zip,
  officeHours,
  accessHours,
}) {
  return (
    <>
      <OverlayBg>
        <StoreInfo>
          <OvelayTitle>{title}</OvelayTitle>
          <StoreWrapper>
            <div>
              <LocationSpot />
            </div>
            <OverlayAddress>
              {address}
              <br />
              {city}, {state}, {zip}
            </OverlayAddress>
          </StoreWrapper>
        </StoreInfo>
        <DividerLine />
        <HoursWrapper>
          <div>
            <Globe />
          </div>
          <Hours>
            <HoursTitle>Office Hours</HoursTitle>
            {officeHours.map((hour, index) => (
              <HoursText key={index}>{hour}</HoursText>
            ))}
          </Hours>
          <div>
            <Clock />
          </div>
          <Hours>
            <HoursTitle>Access Hours</HoursTitle>
            {accessHours.map((hour, index) => (
              <HoursText key={index}>{hour}</HoursText>
            ))}
          </Hours>
        </HoursWrapper>
      </OverlayBg>
    </>
  );
}

export default Overlay;
