import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRentModalOpen: false,
  currentModal: null, // Tracks the currently active modal (e.g., "rent", "reservation")
  currentModalStep: 0,
  selectedUnit: null,
  selectedFacilityId: null,
  selectedReservation: null,
  activeModalSession: false
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { type, unit, facilityId, reservation } = action.payload;
      if(type === 0 || type === 1){
        state.activeModalSession = true
      }
      state.isRentModalOpen = true;
      state.currentModal = type; // "rent" or "reservation"
      state.selectedUnit = unit ? unit : state.selectedUnit || null;
      state.selectedFacilityId = facilityId || null;
      state.selectedReservation = reservation ? reservation : state.selectedReservation || null;
      state.currentModalStep = type; // Reset to step 0 when opening a modal
      
    },
    closeModal: (state) => {
      state.isRentModalOpen = false;
      state.currentModal = null;
      state.currentModalStep = 0;
      state.selectedUnit = null;
      state.selectedFacilityId = null;
      state.selectedReservation = null;
      state.activeModalSession = false;
    },
    nextModalStep: (state, action) => {
      if(action.payload === 1 || action.payload === 2){
        state.activeModalSession = true
      }
      const step = action.payload; // Optional step value
      if (typeof step === "number") {
        state.currentModalStep = Math.min(Math.max(step, 0), 5); // Ensure the step is within bounds (0 to 5)
        state.isRentModalOpen = true
      } else {
        state.currentModalStep = Math.min(state.currentModalStep + 1, 5); // Default increment
      }
    },
    prevModalStep: (state) => {
      state.currentModalStep = Math.max(state.currentModalStep - 1, 0); // Min step is 0
    },
    updateActiveRental: (state, action) => {
      const { unit, facilityId, reservation, step } = action.payload;
      state.selectedUnit = unit || null;
      state.selectedFacilityId = facilityId || null;
      state.selectedReservation = reservation || null;
    },
  },
});

export const { openModal, closeModal, nextModalStep, prevModalStep, updateActiveRental } =
  modalSlice.actions;

export default modalSlice.reducer;
