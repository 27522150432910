import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../api/axios";
import { logout } from "./authSlice"; // Import the logout action

// Async thunk for creating a rental
export const createRental = createAsyncThunk(
  "rentals/createRental",
  async ({ facilityId, tenantId, rentalData }, { rejectWithValue }) => {
    let testCode = rentalData.testCode || null
    delete rentalData.testCode
    let url = testCode?`/facilities/${facilityId}/rentals?simulate=${testCode}`:`/facilities/${facilityId}/rentals`;
    
    try {
      const response = await instance.post(
        url,
        rentalData,
        {
          headers: {
            "encrypted-user-id": tenantId,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addAlternateContact = createAsyncThunk(
  "profile/addAlternateContact",
  async ({ facilityId, tenantId, rentalId, alternateContactData }, { rejectWithValue }) => {
    let testCode = alternateContactData.testCode || null
    delete alternateContactData.testCode
    let url = testCode?`/facilities/${facilityId}/tenants/rentals/${rentalId}/contacts?simulate=${testCode}`:`/facilities/${facilityId}/tenants/rentals/${rentalId}/contacts`;
    alternateContactData.address =  alternateContactData.address1
    try {
      const response = await instance.post(
        url,
        alternateContactData,
        {
          headers: {
            "encrypted-user-id": tenantId,
          },
        }
      );
      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred while adding the alternate contact.");
    }
  }
);

// Async thunk for fetching rentals for a tenant
export const fetchRentalsForTenant = createAsyncThunk(
  "rentals/fetchRentalsForTenant",
  async ({ facilityId, tenantId }, { rejectWithValue }) => {
    try {
      const response = await instance.get(`/facilities/${facilityId}/rentals`, {
        headers: {
          "encrypted-user-id": tenantId,
        },
      });

      // Process rentals to determine alert status
      const rentals = response.data.map((rental) => ({
        ...rental,
        alert:
          rental.leaseStatus.status !== "complete" ||
          (rental.contacts && rental.contacts.length === 0),
      }));

      // Check for any rental with alert = true
      const hasAlert = rentals.some((rental) => rental.alert);
      // const hasAlert = true;

      return { rentals, hasAlert };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Async thunk for fetching reservationsexportconst

const rentalsSlice = createSlice({
  name: "rentals",
  initialState: {
    rentals: null,
    rentLoading: false,
    rentError: null,
    createRentalStatus: null,
    rentSuccessMessage: null,
    selectedRentalId: null,
    hasAlert: false,
    altLoading: false,
    altError: false,
    altSuccessMessage: null,
    alternateContacts: [], // List of alternate contacts
    rentalsReady: false

  },
  reducers: {
    resetCreateRentalStatus: (state) => {
      state.createRentalStatus = null;
    },
    clearSuccessMessage: (state) => {
      state.rentSuccessMessage = null; // Reducer to clear the success message
    },
    clearSelectedRentalId: (state) => {
      state.selectedRentalId = null; // Reducer to clear the selected rental ID
    },
    clearAlert: (state) => {
      state.hasAlert = false; // Reducer to clear the alert flag
    },  
    setRentalsReady: (state) => {
      state.rentalsReady = true
    }
  },
  extraReducers: (builder) => {
    builder
      // Create Rental
      .addCase(createRental.pending, (state) => {
        // state.rentLoading = true;
        state.rentError = null;
        state.createRentalStatus = null;
        state.selectedRentalId = null; // Clear the selected rental ID during a new request
      })
      .addCase(createRental.fulfilled, (state, action) => {
        state.rentLoading = false;
        state.createRentalStatus = "success";
        state.rentals.push(action.payload); // Add the newly created rental to the state
        state.rentSuccessMessage = "Success!"; // Set success message
        state.selectedRentalId = action.payload?.rental_id || null; // Store the rental ID
      })
      .addCase(createRental.rejected, (state, action) => {
        state.rentLoading = false;
        // state.rentError = action.payload;
        // state.createRentalStatus = "failed";
        state.selectedRentalId = null; // Ensure selectedRentalId is cleared on error
      })

      // Fetch Rentals for Tenant
      .addCase(fetchRentalsForTenant.pending, (state) => {
        state.rentLoading = true;
        state.rentError = null;
        state.rentalsReady = false;
      })
      .addCase(fetchRentalsForTenant.fulfilled, (state, action) => {
        state.rentLoading = false;
        state.rentals = action.payload.rentals;
        state.hasAlert = action.payload.hasAlert; // Update hasAlert value
        state.rentalsReady = false;

      })
      .addCase(fetchRentalsForTenant.rejected, (state, action) => {
        state.rentLoading = false;
        state.rentError = action.payload;
      })
      .addCase(logout, () => ({
        rentals: null,
        rentLoading: false,
        rentError: null,
        createRentalStatus: null,
        rentSuccessMessage: null,
        selectedRentalId: null,
        hasAlert: false,
      }))
      .addCase(addAlternateContact.pending, (state) => {
        state.altLoading = true;
        state.altError = null;
      })
      .addCase(addAlternateContact.fulfilled, (state, action) => {
        state.altLoading = false;
        state.alternateContacts.push(action.payload);
        state.altSuccessMessage = "Alternate contact added successfully!";
        state.createRentalStatus = "success";
        state.rentSuccessMessage = "Success!"; // Set success message
      })
      .addCase(addAlternateContact.rejected, (state, action) => {
        state.altLoading = false;
        state.altError = action.payload;
      })
  },
});

export const {
  resetCreateRentalStatus,
  clearSuccessMessage,
  clearSelectedRentalId,
  clearAlert,
  setRentalsReady
} = rentalsSlice.actions;

// Selectors
export const rentals = (state) => state.rentals.rentals;
export const rentalLoading = (state) => state.rentals.rentLoading;
export const rentalError = (state) => state.rentals.rentError;
export const createRentalStatus = (state) =>
  state.rentals.createRentalStatus;
export const successMessage = (state) => state.rentals.rentSuccessMessage;
export const selectedRentalId = (state) => state.rentals.selectedRentalId; // Selector for selected rental ID
export const hasAlert = (state) => state.rentals.hasAlert; // Selector for hasAlert


export default rentalsSlice.reducer;
