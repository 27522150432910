// Import all necessary images
import img10x20VehicleCovered from "../assets/Unit Size Graphics_10x20 Vehicle Covered.png";
import img10x40VehicleCovered from "../assets/Unit Size Graphics_10x40 Vehicle Covered.png";
import img5x10 from "../assets/Unit Size Graphics_5x10.png";
import img10x10 from "../assets/Unit Size Graphics_10x10.png";
import img15x20 from "../assets/Unit Size Graphics_15x20.png";
import img10x15 from "../assets/Unit Size Graphics_10x15.png";
import img10x25 from "../assets/Unit Size Graphics_10x25.png";
import img10x30 from "../assets/Unit Size Graphics_10x30.png";
import img5x5Locker from "../assets/Unit Size Graphics_5x5 Locker.png";
import img10x20VehicleParking from "../assets/Unit Size Graphics_10x20 Vehicle Parking.png";
import img7x10 from "../assets/Unit Size Graphics_7.5x10.png";
import img5x15 from "../assets/Unit Size Graphics_5x15.png";
import img10x40 from "../assets/Unit Size Graphics_10x40.png";
import img5x5 from "../assets/Unit Size Graphics_5x5.png";
import img10x20 from "../assets/Unit Size Graphics_10x20.png";
import SmallUnit from "../assets/small.png";

// Import feature icons
import { ReactComponent as Red8FtCeilings01 } from "../assets/red8ftceilings01.svg";
import { ReactComponent as RedBasementStorage } from "../assets/redbasementstorage.svg";
import { ReactComponent as RedClimateControlled } from "../assets/redclimatecontrolled.svg";
import { ReactComponent as RedCoveredParking01 } from "../assets/redcoveredparking01.svg";
import { ReactComponent as RedDriveUp01 } from "../assets/reddriveup01.svg";
import { ReactComponent as RedElevatorAccess01 } from "../assets/redelevatoraccess01.svg";
import { ReactComponent as RedElevator01 } from "../assets/redelevator01.svg";
import { ReactComponent as RedElevator } from "../assets/redelevator.svg";
import { ReactComponent as RedFirstFloor01 } from "../assets/redfirstfloor01.svg";
import { ReactComponent as RedTallCeiling } from "../assets/redtallceiling.svg";
import { ReactComponent as RedIndoor01 } from "../assets/redindoor01.svg";
import { ReactComponent as RedLightingInUnit01 } from "../assets/redlightinginunit01.svg";
import { ReactComponent as RedMultipleRollUpDoors01 } from "../assets/redmultiplerollupdoors01.svg";
import { ReactComponent as RedMultipleRollUpDoors } from "../assets/redmultiplerollupdoors.svg";
import { ReactComponent as RedNearEntrance01 } from "../assets/rednearentrance01.svg";
import { ReactComponent as RedOutdoor01 } from "../assets/redoutdoor01.svg";
import { ReactComponent as RedPavedParking01 } from "../assets/redpavedparking01.svg";
import { ReactComponent as RedStairs } from "../assets/redstairs.svg";
import { ReactComponent as RedShortCeilings01 } from "../assets/redshortceilings01.svg";
import { ReactComponent as RedSwingDoor01 } from "../assets/redswingdoor01.svg";
import { ReactComponent as RedTallDoor01 } from "../assets/redtalldoor01.svg";
import { ReactComponent as RedTempControlled01 } from "../assets/redtempcontrolled01.svg";
import { ReactComponent as RedUpperFloor01 } from "../assets/redupperfloor01.svg";
import { ReactComponent as RedWineStorage01 } from "../assets/redwinestorage01.svg";

// Unit image map
export const unitImageMap = {
  "5x5 Locker": { src: img5x5Locker, alt: "5x5 Locker Unit" },
  "5x10": { src: img5x10, alt: "5x10 Unit" },
  "10x10": { src: img10x10, alt: "10x10 Unit" },
  "15x20": { src: img15x20, alt: "15x20 Unit" },
  "10x15": { src: img10x15, alt: "10x15 Unit" },
  "10x20 Vehicle Covered": { src: img10x20VehicleCovered, alt: "10x20 Vehicle Covered" },
  "10x40 Vehicle Covered": { src: img10x40VehicleCovered, alt: "10x40 Vehicle Covered" },
  "10x25": { src: img10x25, alt: "10x25 Unit" },
  "10x30": { src: img10x30, alt: "10x30 Unit" },
  "10x20 Vehicle Parking": { src: img10x20VehicleParking, alt: "10x20 Vehicle Parking" },
  "7.5x10": { src: img7x10, alt: "7.5x10 Unit" },
  "5x15": { src: img5x15, alt: "5x15 Unit" },
  "10x40": { src: img10x40, alt: "10x40 Unit" },
  "5x5": { src: img5x5, alt: "5x5 Unit" },
  "10x20": { src: img10x20, alt: "10x20 Unit" },
  default: { src: SmallUnit, alt: "Default Small Unit" },
};

// Valid features set
export const validFeatures = new Set([
  "inside",
  "outside",
  "outside hallway",
  "1st floor",
  "basement",
  "cargo lift",
  "drive up",
  "rolling stairs",
  "elevator",
  "locker",
  "climate controlled",
  "a/c",
  "a/c + heat",
  "dehumidified",
  "heat",
  "heated & cooled w/ humidity control",
  "multiple mixed door",
  "oversized door",
  "roll-up door",
  "roll-up door multiple",
  "small door",
  "swing door",
  "enclosed parking",
  "covered parking",
  "uncovered parking",
  "24 access",
  "container",
  "drive thru",
]);

// Feature icon mapping
export function getFeatureIconAndTitle(feature) {
  const iconStyle = { height: "20px" };

  let featureIcon = null;
  let featureTitle = feature;

  switch (feature.toLowerCase()) {
    case "inside":
      featureIcon = (
        <div style={iconStyle}>
          <RedIndoor01 />
        </div>
      );
      featureTitle = "Inside";
      break;
    case "outside":
      featureIcon = (
        <div style={iconStyle}>
          <RedOutdoor01 />
        </div>
      );
      featureTitle = "Outside";
      break;
    case "outside hallway":
      featureIcon = (
        <div style={iconStyle}>
          <RedOutdoor01 />
        </div>
      );
      featureTitle = "Outside Hallway";
      break;
    case "1st floor":
      featureIcon = (
        <div style={iconStyle}>
          <RedFirstFloor01 />
        </div>
      );
      featureTitle = "1st Floor";
      break;
    case "basement":
      featureIcon = (
        <div style={iconStyle}>
          <RedBasementStorage />
        </div>
      );
      featureTitle = "Basement";
      break;
    case "annex":
    case "annex 2":
    case "annex 3":
      featureIcon = (
        <div style={iconStyle}>
          <RedTallCeiling />
        </div>
      );
      featureTitle = "Annex";
      break;
    case "cargo lift":
      featureIcon = (
        <div style={iconStyle}>
          <RedElevator />
        </div>
      );
      featureTitle = "Cargo Lift";
      break;
    case "drive up":
      featureIcon = (
        <div style={iconStyle}>
          <RedDriveUp01 />
        </div>
      );
      featureTitle = "Drive Up Access";
      break;
    case "rolling stairs":
      featureIcon = (
        <div style={iconStyle}>
          <RedStairs />
        </div>
      );
      featureTitle = "Rolling Stairs";
      break;
    case "stair only":
      featureIcon = (
        <div style={iconStyle}>
          <RedStairs />
        </div>
      );
      featureTitle = "Stairs Only";
      break;
    case "elevator":
      featureIcon = (
        <div style={iconStyle}>
          <RedElevator />
        </div>
      );
      featureTitle = "Elevator";
      break;
    case "locker":
      featureIcon = (
        <div style={iconStyle}>
          <RedWineStorage01 />
        </div>
      );
      featureTitle = "Locker";
      break;
    case "reduced height":
      featureIcon = (
        <div style={iconStyle}>
          <RedShortCeilings01 />
        </div>
      );
      featureTitle = "Reduced Height";
      break;
    case "climate controlled":
      featureIcon = (
        <div style={iconStyle}>
          <RedClimateControlled />
        </div>
      );
      featureTitle = "Climate Controlled";
      break;
    case "non climate":
      featureIcon = (
        <div style={iconStyle}>
          <RedIndoor01 />
        </div>
      );
      featureTitle = "Non Climate";
      break;
    case "a/c":
      featureIcon = (
        <div style={iconStyle}>
          <RedTempControlled01 />
        </div>
      );
      featureTitle = "Cooled";
      break;
    case "a/c + heat":
      featureIcon = (
        <div style={iconStyle}>
          <RedTempControlled01 />
        </div>
      );
      featureTitle = "Heated & Cooled";
      break;
    case "dehumidified":
      featureIcon = (
        <div style={iconStyle}>
          <RedClimateControlled />
        </div>
      );
      featureTitle = "Dehumidified";
      break;
    case "heat":
      featureIcon = (
        <div style={iconStyle}>
          <RedTempControlled01 />
        </div>
      );
      featureTitle = "Heated";
      break;
    case "heated & cooled w/ humidity control":
      featureIcon = (
        <div style={iconStyle}>
          <RedTempControlled01 />
        </div>
      );
      featureTitle = "Heated & Cooled w/ Humidity Control";
      break;
    case "multiple mixed door":
      featureIcon = (
        <div style={iconStyle}>
          <RedMultipleRollUpDoors />
        </div>
      );
      featureTitle = "Multiple Mixed Door";
      break;
    case "oversized door":
      featureIcon = (
        <div style={iconStyle}>
          <RedTallDoor01 />
        </div>
      );
      featureTitle = "Oversized Door";
      break;
    case "roll-up door":
      featureIcon = (
        <div style={iconStyle}>
          <RedMultipleRollUpDoors />
        </div>
      );
      featureTitle = "Roll-Up Door";
      break;
    case "roll-up door multiple":
      featureIcon = (
        <div style={iconStyle}>
          <RedMultipleRollUpDoors01 />
        </div>
      );
      featureTitle = "Roll-Up Door Multiple";
      break;
    case "small door":
      featureIcon = (
        <div style={iconStyle}>
          <RedSwingDoor01 />
        </div>
      );
      featureTitle = "Small Door";
      break;
    case "swing door":
      featureIcon = (
        <div style={iconStyle}>
          <RedSwingDoor01 />
        </div>
      );
      featureTitle = "Swing Door";
      break;
    case "enclosed parking":
      featureIcon = (
        <div style={iconStyle}>
          <RedCoveredParking01 />
        </div>
      );
      featureTitle = "Enclosed Parking";
      break;
    case "covered parking":
      featureIcon = (
        <div style={iconStyle}>
          <RedCoveredParking01 />
        </div>
      );
      featureTitle = "Covered Parking";
      break;
    case "uncovered parking":
      featureIcon = (
        <div style={iconStyle}>
          <RedPavedParking01 />
        </div>
      );
      featureTitle = "Uncovered Parking";
      break;
    case "24 access":
      featureIcon = (
        <div style={iconStyle}>
          <RedTallCeiling />
        </div>
      );
      featureTitle = "24 Access";
      break;
    case "ada":
      featureIcon = (
        <div style={iconStyle}>
          <RedTallCeiling />
        </div>
      );
      featureTitle = "ADA";
      break;
    case "container":
      featureIcon = (
        <div style={iconStyle}>
          <RedTallCeiling />
        </div>
      );
      featureTitle = "Container";
      break;
    case "cylinder lock":
      featureIcon = (
        <div style={iconStyle}>
          <RedTallCeiling />
        </div>
      );
      featureTitle = "Cylinder Lock Only";
      break;
    case "drive thru":
      featureIcon = (
        <div style={iconStyle}>
          <RedDriveUp01 />
        </div>
      );
      featureTitle = "Drive Thru";
      break;
    case "unique":
      featureIcon = (
        <div style={iconStyle}>
          <RedTallCeiling />
        </div>
      );
      featureTitle = "Unique";
      break;
    case "virtual conversion":
      featureIcon = (
        <div style={iconStyle}>
          <RedTallCeiling />
        </div>
      );
      featureTitle = "Virtual Conversion";
      break;
    default:
      featureIcon = (
        <div style={iconStyle}>
          <RedTallCeiling />
        </div>
      );
      featureTitle = feature;
      break;
  }

  return { featureIcon, featureTitle };
}
