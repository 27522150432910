import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import HelpIcon from "./HelpIcon";
import { ReactComponent as Phone } from "../assets/phone-solid.svg";
import { ReactComponent as Warning } from "../assets/email.svg";
import { ReactComponent as Chat } from "../assets/comment_dots.svg";
import { setWidgetLoaded, selectIsWidgetLoaded } from "../redux/widgetSlice";

const HelperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #fff;
  margin: 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.1);
`;

const HelperIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px;
`;

const Border = styled.div`
  border-left: 1px solid #bebebe;
  border-right: 1px solid #bebebe;
`;

function HelperIcons() {
  const dispatch = useDispatch();
  const isWidgetLoaded = useSelector(selectIsWidgetLoaded);

  useEffect(() => {
    if (!isWidgetLoaded) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;
      script.src = "https://widget.csrscape.com/init.js";
      script.onload = () => {
        if (window.csrscape) {
          window.csrscape.init("https://widget.csrscape.com", "9c92634c-a98d-4bab-85a3-431092cf4ad7");
          dispatch(setWidgetLoaded()); // Update Redux state to indicate widget has loaded
        }
      };
      document.body.appendChild(script);
    }
  }, [isWidgetLoaded, dispatch]);

  return (
    <HelperWrapper>
      <HelperIconWrapper>
        <HelpIcon helperIcon={<Phone onClick={() => {window.location.href = 'tel:8444341150';}} />} text="Contact us" />
        {/* <Border />
        <HelpIcon helperIcon={<Chat />} text="Chat with us" /> */}
        <Border />
        <HelpIcon helperIcon={<Warning onClick={() => {window.open('https://www.nsastorage.com/contact-us', '_blank', 'noopener,noreferrer');}} />} text="Report an issue" />
      </HelperIconWrapper>
    </HelperWrapper>
  );
}

export default HelperIcons;
