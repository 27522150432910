import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContext } from './UnitMap';

const Floor = ({ id }) => {
  const { map, levels } = useContext(MapContext);

  useEffect(() => {
    if (!map) {
      return;
    }

    let selection = levels.has({ floor: true });
    selection.where({ floor: id }).show();
    selection.except({ floor: id }).hide();

    // Since there are no dependencies that would change,
    // this effect will run only once when the component mounts.
  }, [map, levels, id]);

  // The component does not render anything, it only executes logic.
  return null;
};

Floor.propTypes = {
  id: PropTypes.string
};

export default Floor;