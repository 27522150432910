import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../api/axios";
import { logout } from "./authSlice"; // Import the logout action

// Async thunk for creating a payment
export const createPayment = createAsyncThunk(
  "payments/createPayment",
  async ({ facilityId, tenantId, paymentData }, { rejectWithValue }) => {
    try {
      const response = await instance.post(
        `/facilities/${facilityId}/payments`,
        paymentData,
        {
          headers: {
            "encrypted-user-id": tenantId,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching transactions
export const fetchTransactions = createAsyncThunk(
  "payments/fetchTransactions",
  async ({ facilityId, tenantId }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/facilities/${facilityId}/transactions`,
        {
          headers: {
            "encrypted-user-id": tenantId,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching charges
export const fetchCharges = createAsyncThunk(
  "manage/fetchCharges",
  async ({ facilityId, tenantId }, { rejectWithValue }) => {
    try {
      const response = await instance.get(`/facilities/${facilityId}/charges`, {
        headers: {
          "encrypted-user-id": tenantId,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching autopays
export const fetchAutopays = createAsyncThunk(
  "payments/fetchAutopays",
  async (facilityId, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/facilities/${facilityId}/autopays`,
        {
          headers: {
            "encrypted-user-id": tenantId,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for creating an autopay
export const createAutopay = createAsyncThunk(
  "payments/createAutopay",
  async ({ facilityId, autopayData }, { rejectWithValue }) => {
    try {
      const response = await instance.post(
        `/facilities/${facilityId}/autopays`,
        autopayData,
        {
          headers: {
            "encrypted-user-id": tenantId,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const paymentsSlice = createSlice({
  name: "payments",
  initialState: {
    payments: [],
    charges: [],
    autopays: [],
    transactions: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create Payment
      .addCase(createPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.payments.push(action.payload);
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Charges
      .addCase(fetchCharges.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCharges.fulfilled, (state, action) => {
        state.loading = false;
        state.charges = action.payload;
      })
      .addCase(fetchCharges.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Autopays
      .addCase(fetchAutopays.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAutopays.fulfilled, (state, action) => {
        state.loading = false;
        state.autopays = action.payload;
      })
      .addCase(fetchAutopays.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create Autopay
      .addCase(createAutopay.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAutopay.fulfilled, (state, action) => {
        state.loading = false;
        state.autopays.push(action.payload);
      })
      .addCase(createAutopay.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Transactions
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logout, () => ({
        payments: [],
        charges: [],
        autopays: [],
        transactions: [],
        loading: false,
        error: null,
      }));
  },
});

export default paymentsSlice.reducer;
