// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import instance from "../api/axios";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    email: "",
    first: "",
    loading: false,
    loaded: false,
    error: null,
    profile: null,
    s: "",
    i: null,
  },
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
      state.loaded = false;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.email = action.payload.email;
      state.first = action.payload.first_name;
      state.profile = action.payload;
      state.s = action.payload.s;
      state.i = action.payload.i;
      state.loaded = true;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.loaded = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.email = "";
      state.first = "";
      state.profile = null;
      state.i = null;
      state.loaded = false;
    },
    saveStore: (state, action) => {
      state.s = action.payload;
    },
  },
});

export const loginWithQuery = (s, i) => async (dispatch) => {
  dispatch(loginStart());
  try {
    if(i){
      const response = await instance.get(`/login?store=${s}&i=${i}`, {
        headers: {
          'encrypted-user-id': i,
        }
      });
      dispatch(loginSuccess({ ...response.data.data, s, i }));
    }else{
      return
    }
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

export const logoutUser = () => (dispatch, getState) => {
  const state = getState();
  const storeId = state.auth.s;
  dispatch(logout());
  dispatch(saveStore(storeId));
};

export const { loginStart, loginSuccess, loginFailure, logout, saveStore } = authSlice.actions;
export const selectedStore = (state) => state.auth.s;
export const selectedTenantId = (state) => state.auth.i; // Add the selector for tenantId
export default authSlice.reducer;
