// src/redux/widgetSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./authSlice"; // Import the logout action

const widgetSlice = createSlice({
  name: "widget",
  initialState: {
    isLoaded: false,
  },
  reducers: {
    setWidgetLoaded(state) {
      state.isLoaded = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // Reset state on logout
      .addCase(logout, (state) => {
        state.isLoaded = false;
      });
  },
});

export const { setWidgetLoaded } = widgetSlice.actions;
export const selectIsWidgetLoaded = (state) => state.widget.isLoaded;
export default widgetSlice.reducer;
