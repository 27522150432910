import styled from "styled-components";

const HelpIconContainer = styled.div`
display: flex ;
flex-direction: column ;
align-items: center ;
padding: 5px ;
  gap: 4px;
`

const HelpIconText = styled.span`
color: #E22C2A;
font-size: 10px;
font-style: normal;
font-weight: 600;
`
const HelpIconImageWrap = styled.div`
  width: 20px;
  height: 20px;
  color: #d32f2f;
`


function HelpIcon (obj) {
return (
        <HelpIconContainer>
            <HelpIconImageWrap>{obj.helperIcon}</HelpIconImageWrap>
                <HelpIconText>{obj.text}</HelpIconText>
        </HelpIconContainer>

);

}

export default HelpIcon;