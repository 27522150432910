import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../api/axios";
import { logout } from "./authSlice"; // Import the logout action

// Helper function to check if an image exists
const checkImage = (imgUrl) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = imgUrl;
  });
};

// Helper function to group hours
const groupHours = (hours) => {
  const daysOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const grouped = {};
  for (const [day, { closed, openTime, closeTime }] of Object.entries(hours)) {
    const key = closed ? "Closed" : `${openTime} - ${closeTime}`;
    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(day.charAt(0).toUpperCase() + day.slice(1));
  }
  const formatted = [];
  for (const [time, days] of Object.entries(grouped)) {
    const ranges = [];
    let rangeStart = days[0];
    let rangeEnd = days[0];
    for (let i = 1; i < days.length; i++) {
      if (daysOrder.indexOf(days[i]) === daysOrder.indexOf(rangeEnd) + 1) {
        rangeEnd = days[i];
      } else {
        ranges.push(
          rangeStart === rangeEnd ? rangeStart : `${rangeStart}-${rangeEnd}`
        );
        rangeStart = days[i];
        rangeEnd = days[i];
      }
    }
    ranges.push(
      rangeStart === rangeEnd ? rangeStart : `${rangeStart}-${rangeEnd}`
    );
    formatted.push(`${ranges.join(", ")}: ${time}`);
  }
  return formatted;
};

// Async thunk to fetch store information
export const fetchFacility = createAsyncThunk(
  'facilities/fetchFacility',
  async (storeId, { rejectWithValue }) => {
    // storeId = ""
    try {
      const response = await instance.get(`/facilities/${storeId}`);
      const data = response.data;

      // Remove notes property if it exists
      if (Array.isArray(data)) {
        data.forEach(facility => {
          delete facility.notes;
        });
      } else if (typeof data === 'object') {
        delete data.notes;
      }

      const imgUrl = `https://mm-facility-pics.s3.amazonaws.com/${storeId}/beauty_shot`;
      const isImage = await checkImage(imgUrl);
      const beautyShot = isImage ? imgUrl : 'https://images.pexels.com/photos/612949/pexels-photo-612949.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';

      if (Array.isArray(data)) {
        data.forEach(facility => {
          facility.beautyShot = beautyShot;
          facility.officeHours = groupHours(facility.office_hours.hours);
          facility.accessHours = groupHours(facility.access_hours.hours);
        });
      } else if (typeof data === 'object') {
        data.beautyShot = beautyShot;
        data.officeHours = groupHours(data.office_hours.hours);
        data.accessHours = groupHours(data.access_hours.hours);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const facilitiesSlice = createSlice({
  name: "facilities",
  initialState: {
    facilities: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFacility.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFacility.fulfilled, (state, action) => {
        state.loading = false;
        state.facilities[action.meta.arg] = action.payload;
      })
      .addCase(fetchFacility.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectFacilityById = (state, storeId) => state.facilities.facilities[storeId];

export default facilitiesSlice.reducer;
