import styled, {keyframes} from "styled-components";
import React, { useState, useEffect } from "react";
import { ReactComponent as Back } from "../assets/BackButton.svg";
import { ReactComponent as NSALogo2 } from "../assets/nsaLogo2.svg";
import SouthernLogo from "../assets/southern.webp";
import IstorageLogo from "../assets/istorage.webp"; // Add other logos similarly
import MoveItLogo from "../assets/moveit.webp"; // Example for Move It Storage
import MooveInLogo from "../assets/moovein.webp";
import HideAwayLogo from "../assets/hideaway.webp";
import NorthwestLogo from "../assets/northwest.webp";
import RightSpaceLogo from "../assets/rightspace.webp";
import SecureCareLogo from "../assets/securcare.webp";
import TestFacilityLogo from "../assets/testfacility.webp";
import { logoutUser, selectedStore } from '../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { selectFacilityById } from "../redux/facilitiesSlice";
import { fetchRentalsForTenant} from "../redux/rentalsSlice";


const NewHeaderWrapper = styled.div`
  position: sticky; /* Makes the header sticky */
  top: 0; /* Ensures the header sticks to the top of the viewport */
  z-index: 1000; /* Keeps the header above other content */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
`;
const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;
const MessageAlertWrapper = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 999; /* Ensure it appears behind the sticky header */
  background-color: #e22c2a;
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  transform: translateY(-100%); /* Hidden by default */
  animation: ${({ show }) => (show ? slideDown : "none")} 0.5s forwards;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;
const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: bold;
  color: #e22c2a;
  gap: 4px;
  font-size: 16px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
const LogoDivider = styled.div`
  content: " ";
  border-right: 1.5px #e22c2a solid;
`;

const LoginText = styled.button`
  color: #e22c2a;
  border: none;
  background: none;
  font-size: 16px;
`;
const PartnerLogoImg = styled.img`
  height: 50px;
`;

function NewHeader(obj) {
  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

  const dispatch = useDispatch();
  const hasAlert = useSelector((state) => state.rentals.hasAlert);
  const { rentals } = useSelector((state) => state.rentals);
  const { isAuthenticated, email, first, loading, loaded, s, i } = useSelector((state) => state.auth);

  const storeId = useSelector(selectedStore);
  const facility = useSelector((state) => selectFacilityById(state, storeId));

  useEffect(() => {
    if (!rentals && storeId && i) {
      dispatch(fetchRentalsForTenant({ facilityId: storeId, tenantId: i }));
    }
  }, [dispatch, storeId, i, rentals]);

  useEffect(() => {
    if (hasAlert) {
      console.log("There are unresolved issues with the rentals!");
      setShowAlert(true); // Show the alert if there is an issue
    } else {
      setShowAlert(false); // Automatically hide the alert when `hasAlert` is resolved
    }
  }, [hasAlert]);

  const brandMap = {
    7: { name: "Istorage", logo: IstorageLogo },
    8: { name: "Move It Storage", logo: MoveItLogo },
    9: { name: "SecurCare", logo: SecureCareLogo },
    10: { name: "Northwest", logo: NorthwestLogo },
    11: { name: "Move It", logo: MoveItLogo },
    12: { name: "Moove In Self Storage", logo: MooveInLogo },
    13: { name: "Moove In Storage", logo: MooveInLogo },
    17: { name: "Test Facility", logo: IstorageLogo },
    19: { name: "Hide", logo: HideAwayLogo },
    53: { name: "Rightspace Storage", logo: RightSpaceLogo },
    64: { name: "Southern", logo: SouthernLogo },
    // Add other brand mappings here
  };

  const brand = brandMap[obj.brand_id];
  const getPartnerLogo = (brand_id) => {
    if (brandMap[brand_id]) {
      return (
        <PartnerLogoImg
          id={`logo-${brand_id}`}
          src={brandMap[brand_id].logo}
          alt={brandMap[brand_id].name}
        />
      );
    } else {
      return null;
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };
  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/");
  };
  const handleNavToMyReservations = () => {
    navigate("/myreservations");
  };

  return (
    <>
      <MessageAlertWrapper id="alert-message" onClick={handleNavToMyReservations} show={showAlert}>
        Your rental is incomplete!
      </MessageAlertWrapper>
      <NewHeaderWrapper>
        {!obj.home && (
          <BackButtonContainer id="back-button" onClick={handleGoBack}>
            <Back /> Back
          </BackButtonContainer>
        )}
        <LogoContainer>
          <NSALogo2 id="nsa-logo" onClick={() => navigate("/")} />
          {brand && <LogoDivider />}
          {brand && getPartnerLogo(obj.brand_id)}
        </LogoContainer>
        {obj.auth ? (
          <LoginText id="logout-button" onClick={handleLogout}>
            Logout
          </LoginText>
        ) : (
          <LoginText id="login-button" onClick={() => navigate("/login")}>
            Login
          </LoginText>
        )}
      </NewHeaderWrapper>
    </>
  );
}

export default NewHeader;