import styled from "styled-components";
import React from "react";

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  max-width: 425px;
`;

const InputLabel = styled.label`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0 5px;
`;

const StyledInput = styled.input`
  width: ${(props) => props.width || "400px"};
  padding: ${(props) => props.padding || "10px"};
  margin: ${(props) => props.margin || "5px 0"};
  border: ${(props) => props.border || "1.5px solid #ccc"};
  border-radius: ${(props) => props.borderRadius || "10px"};
  font-size: ${(props) => props.fontSize || "16px"};
  box-sizing: border-box;

  &:focus {
    border-color: ${(props) => props.focusBorderColor || "#007BFF"};
    outline: none;
  }
  width: 100%;
  max-width: 425px;
`;

const Input = ({ id, labelText, onChange, placeholder, dataTestId }) => {
  return (
    <InputWrapper>
      <InputLabel htmlFor={id}>{labelText}</InputLabel>
      <StyledInput
        id={id}
        type="text"
        onChange={onChange}
        placeholder={placeholder || ""}
        data-testid={dataTestId || id}
      />
    </InputWrapper>
  );
};

export default Input;
