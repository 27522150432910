import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

const BBContainer = styled.div`
display: flex ;
justify-content: center ;
margin-top: 25px ;
max-width: 425px;
width: 90%;
`


const BB = styled.button`
width: 248px;
max-width: 425px;
width: 90%;
height: 58px;
flex-shrink: 0;
border-radius: 10px;
background: #e22c2a;
color: #FFF;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 150% */
letter-spacing: -0.24px;
border: none ;
box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
`

function BottomButton (obj) {
    let navigate = useNavigate();
    return (
       <BBContainer> 
            <BB onClick={()=>{navigate(obj.land)}}>{obj.title}</BB>
        </BBContainer>
    );
}

export default BottomButton ;