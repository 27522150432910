import styled from "styled-components";
import { ReactComponent as Arrow } from "../assets/arrow.svg";
import Unit from "./Unit";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faCircleCheck,
  faCircleDollarToSlot,
} from "@fortawesome/free-solid-svg-icons";

function Reservation({
  reservation,
  facility,
  isRentModalOpen,
  toggleRentModal,
  leaseSignUrl,
  complete,
  rentalStatus,
  onNext,
  onClose,
}) {
  let [panelOpen, setPanelOpen] = useState(isRentModalOpen);
  // const [state, setState] = useState(initialState)
  const ResWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    background-color: #d4d5d5;
    justify-content: space-between;
    paddingbottom: 0;
    width: 100%;
    max-width: 425px;
    width: 90%;
    marginbottom: 5px;
  `;

  const ResInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: ${(props) => props.align || "flex-start"};
  `;
  const ResInfoWrapperRight = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-end;
  `;

  const ResName = styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  `;
  const ResDate = styled.p`
    color: #767374;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 2px 0;
  `;
  const ResTime = styled.p`
    color: #767374;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  `;

  const ResDetails = styled.button`
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: none;
    border: none;
    margin: 2px 0;
    padding: 0;
  `;
  const ResUnit = styled.div`
    background: #d4d5d5;
    margin-bottom: 10px;
    max-width: 425px;
    width: 95%;
  `;
  const AlertWrapper = styled.span`
    display: flex;
    align-items: center;
    color: #e22c2a; /* Alert color */
    font-size: 14px;
    margin-top: 4px;
    background: #fff;
    padding: 3px;
    border-radius: 4px;
  `;
  const Icon = styled(FontAwesomeIcon)`
    left: 10px;
    color: #e22c2a;
    margin-right: 5px;
  `;

  return (
    <>
      <ResWrapper>
        <ResInfoWrapper>
          <ResName>
            {reservation?.name || reservation?.unitDetails?.name}
          </ResName>
          {reservation?.created_at && (
            <>
              <ResDate>
                {new Date(reservation?.created_at).toLocaleDateString()}
              </ResDate>
              <ResTime>
                Reserved{" "}
                {new Date(reservation?.created_at).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </ResTime>
            </>
          )}
          {reservation?.paid_through && (
            <>
              <ResDate>
                Paid Until:
                {new Date(reservation?.paid_through).toLocaleDateString()}
              </ResDate>
            </>
          )}
        </ResInfoWrapper>

        <ResInfoWrapperRight
          align="center"
          onClick={() => setPanelOpen((prev) => !prev)}
        >
          <ResDetails>
            View Details{" "}
            <span style={{ color: "#e22c2a" }}>{panelOpen ? "▲" : "▼"}</span>
          </ResDetails>
          {leaseSignUrl && (
            <AlertWrapper>
              <Icon icon={faCircleExclamation} />
              Not Signed
            </AlertWrapper>
          )}
          {rentalStatus &&
            rentalStatus === "complete" &&
            reservation?.rentalInfo?.contacts[0] && (
              <AlertWrapper>
                <Icon icon={faCircleCheck} />
                Ready to Move In
              </AlertWrapper>
            )}
          {reservation.rentalInfo &&
            !(reservation?.rentalInfo?.contacts?.length > 0) && (
              <AlertWrapper>
                <Icon icon={faCircleExclamation} />
                Missing Alt Contact
              </AlertWrapper>
            )}
          {/* {reservation.rentalInfo && !reservation.rentalInfo.contacts && (
            <AlertWrapper>
              <Icon icon={faCircleExclamation} />
              Need Additional Info
            </AlertWrapper>
          )} */}
          {!reservation.rentalInfo && reservation.status !== "Rented" && (
            <AlertWrapper>
              <Icon icon={faCircleDollarToSlot} />
              Ready for Payment
            </AlertWrapper>
          )}
          {!reservation.rentalInfo && reservation.status === "Rented" && (
            <AlertWrapper>
              <Icon icon={faCircleExclamation} />
              No Gate Code!
            </AlertWrapper>
          )}
        </ResInfoWrapperRight>
      </ResWrapper>
      {panelOpen && (
        <ResUnit>
          <Unit
            key={reservation?.id}
            cancelButton={true}
            from="reservation"
            reservationId={reservation?.id}
            reservation={reservation}
            unitName={reservation?.unitDetails?.name}
            unitSize={`${reservation?.unitDetails?.width}x${reservation?.unitDetails?.length}`}
            unitPrice={reservation?.unitDetails?.price.street_rate}
            street_rate={reservation?.unitDetails?.price.street_rate}
            web_rate={reservation?.unitDetails?.price.web_rate}
            isRentModalOpen={isRentModalOpen}
            toggleRentModal={toggleRentModal}
            onClose={onClose}
            discount={reservation?.unitDetails?.price.web_rate}
            where="In-Store"
            featureText={reservation?.unitDetails?.features}
            unit={reservation?.unitDetails}
            facility={facility}
            leaseSignUrl={leaseSignUrl}
            complete={complete}
            rentalStatus={rentalStatus}
            onNext={onNext}
          />
        </ResUnit>
      )}
      <div style={{ marginBottom: "5px" }}></div>
    </>
  );
}

export default Reservation;
